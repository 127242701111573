import React, { Component } from 'react';

import BuildingSelect from './CesiumGlobe/BuildingSelect'
import axios from 'axios'

class CesiumGlobe extends Component {
    state = {
        buildingSelected: false,
        viewer: null,
        dataLayer: null,
        list: []
    }

    componentDidMount() {
        let { Cesium, WebMap3DCityDB, CitydbKmlLayer, navigationInitialization } = window

        axios.get('https://sheets.googleapis.com/v4/spreadsheets/1uRGfZlVDOFqgmFugKLpxjOcpI0Z6UTnWD0LZUAlUdxk/values/A:B?key=AIzaSyBWWQ4A3fn5Ben4Jyo9GdFsB5l989NASyM')
            .then(res=>{
                let arr = JSON.parse(JSON.stringify(res.data.values))
                let header = arr.shift()

                let list = arr.map(item =>
                    item.reduce((result, field, index) => {
                        result[header[index]] = field
                        return result
                    }, {})
                )
                this.setState({list})
            })
            .catch(err => console.log(err))

        // set required api tokens
        // Cesium.BingMapsApi.defaultKey = 'ApOW9LMkerqWIVSnFauilSeaZyp8df66byy1USCTjgTdMvhb4y1iAhEsUHQfCgzq'
        Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3NWU2NWNjNi0wZDljLTRlYmYtYWY0Zi0wMjlmYjJjMWNiY2IiLCJpZCI6MTEzMTEsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NTg1OTk3NTJ9.1Ks7PBW336AyYzKTYwQzoZXeHh9-zElPMNyktekLVTc'

        let viewer = new Cesium.Viewer(this.cesiumContainer, {
            animation: false,
            baseLayerPicker: false,
            fullscreenButton: false,
            geocoder: false,
            homeButton: false,
            infoBox: false,
            sceneModePicker: false,
            selectionIndicator: false,
            timeline: false,
            navigationHelpButton: false,
            scene3DOnly: true,
            shadows: true,
            imageryProvider: new Cesium.BingMapsImageryProvider({
                url: 'https://dev.virtualearth.net',
                key: 'ApOW9LMkerqWIVSnFauilSeaZyp8df66byy1USCTjgTdMvhb4y1iAhEsUHQfCgzq',
                mapStyle: Cesium.BingMapsStyle.AERIAL
            }),
            terrainProvider: new Cesium.createWorldTerrain({requestVertexNormals: true}),
        })

        // add navigation plugin
        navigationInitialization(this.cesiumContainer, viewer)

        // extra viewer settings
        viewer.scene.globe.depthTestAgainstTerrain = true  // hides buildings that are behind terrain
        viewer.scene.globe.enableLighting = true  // enable globe lighting
        viewer.scene.fog.enabled = true  // enable fog when looking on distant objects

        // set default camera position
        viewer.camera.setView({
            destination: new Cesium.Cartesian3(4013612.603179985, 1197493.6624308773, 4805169.870055832),
            orientation: {
                heading: 0.0,  // x-y axis
                pitch: -Cesium.Math.PI_OVER_FOUR,  // y-z axis
                roll: 0.0 // x-z axis
            },
        })

        // initialize webmap object and enable various events
        let webMap = new WebMap3DCityDB(viewer)
        webMap.activateViewChangedEvent(true)
        webMap.activateMouseMoveEvents(true)
        webMap.activateMouseClickEvents(true)

        // create and insert data layer
        let dataLayer = new CitydbKmlLayer({
            url: './data/novy_liskovec_collada_MasterJSON.json'
        })
        webMap.addLayer(dataLayer)


        // fly to novy liskovec location after 5 sec
        setTimeout(
            () => viewer.camera.flyTo({
                destination: new Cesium.Cartesian3(4005787.3876324147, 1190898.9729319254, 4803636.3789123185),
                orientation: {
                    heading: 0.0,  // x-y axis
                    pitch: -Cesium.Math.PI_OVER_FOUR,  // y-z axis
                    roll: 0.0 // x-z axis
                },
                easingFunction: Cesium.EasingFunction.CUBIC_OUT,
            }),
            localStorage.getItem('showWelcomeScreen') === 'false' ? 3000 : 5000
        )

        // save important references and set page as loaded
        this.setState(() => ({
            viewer,
            dataLayer,
        }))

        // handle mouse clicks
        dataLayer.registerEventHandler('CLICK', object => {
                if (object && object.primitive instanceof Cesium.Model) {
                    setTimeout(()=>
                        this.setState(() => ({ selected: object.id })),
                        100
                    )
                }
            }
        )

        // remove default double-click behavior (entity tracking)
        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)

        // make viewer variable global for development
        let { location } = window
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            window.viewer = viewer
        }
    }

    createScreenshot = () => {
        let { viewer } = this.state
        viewer.render();
        var imageUri = viewer.canvas.toDataURL();
        var imageWin = window.open("");
        imageWin.document.write("<html><head>" +
            "<title>Screenshot</title></head><body>" +
            '<img src="' + imageUri + '"width="100%">' +
            "</body></html>");
        return imageWin;
    }

    drawerClose = () => {
        this.setState(() => ({ selected: null }))
        this.state.dataLayer.unHighlightAllObjects()
    }

    filterList = () => this.state.list.find(elem => this.state.selected ? elem.GMLID === this.state.selected._name : false)

    render() {
        const containerStyle = {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'fixed',
            display: 'flex',
            alignItems: 'stretch',
        }

        let { selected } = this.state

        return (
            <div>
                <div className="cesiumContainer" ref={element => this.cesiumContainer = element} style={containerStyle} />
                <BuildingSelect selected={ selected } buildingData={ this.filterList } onClose={ this.drawerClose } />
            </div>
        );
    }
}

export default CesiumGlobe
