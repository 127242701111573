import React, { Component } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

class WelcomeScreen extends Component {
    state = {
        opened: localStorage.getItem('showWelcomeScreen') === 'false' ? false : true,
        dontShowAgain: false,
    }

    handleClose = () => {
        this.setState({ opened: false })
        if (this.state.dontShowAgain) {
            localStorage.setItem('showWelcomeScreen', 'false')
        }
    }

    handleCheckbox = () => {
        this.setState((state) => ({ dontShowAgain: !state.dontShowAgain }))
    }

    render() {
        const actions = (
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ paddingLeft: 15, paddingRight: 15, }}
            >
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                value="dontShowAgain"
                                onChange={this.handleCheckbox}
                            />
                        }
                        label="Znovu neukazovat"
                    />
                </FormGroup>
                <Button
                variant="outlined"
                    onClick={this.handleClose}
                >
                    Souhlasím
                </Button>
            </Grid>
        )

        return (
            <Dialog
                open={this.state.opened}
                onRequestClose={this.handleClose}
                aria-labelledby="smart-dialog-title"
                aria-describedby="smart-dialog-description"
            >
                <DialogTitle id="smart-dialog-title">{"Smartcity Brno - Nový Lískovec"}</DialogTitle>
                    <DialogContent>
                        <Typography paragraph align="justify">
                            Aplikace vznikla v rámci řešení projektu <a href="http://www.inteligentniregiony.cz">Inteligentní regiony – Informační modelování budov a sídel, technologie a infrastruktura pro udržitelný rozvoj (TE02000077)</a>.<br />
                        </Typography>
                        <Typography align="justify">
                            Pro správné zobrazení je potřeba prohlížeč s podporou technologie WebGL.
                        </Typography>
                        <Typography align="justify">
                            Stránka používá <a href="https://www.vutbr.cz/cookies">soubory cookies</a>.
                        </Typography>
                </DialogContent>
                <DialogActions>
                    { actions }
                </DialogActions>
            </Dialog>
        );
    }
}

export default WelcomeScreen
