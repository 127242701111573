import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import './index.css';
import App from './App';
import Chart from './Chart'

ReactDOM.render(
    <Router>
        <Route exact path="/" component={App} />
        <Route path="/chart" component={Chart} />
    </Router>,
    document.getElementById('root')
);
