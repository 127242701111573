import React, { Component } from 'react';

import Chartjs from 'chart.js'

const colors = (alpha = 1) => ({
    blue: `rgba(54, 162, 235, ${alpha})`,
    green: `rgba(75, 192, 192, ${alpha})`,
    grey: `rgba(201, 203, 207, ${alpha})`,
    orange: `rgba(255, 159, 64, ${alpha})`,
    purple: `rgba(153, 102, 255, ${alpha})`,
    red: `rgba(255, 99, 132, ${alpha})`,
    yellow: `rgba(255, 205, 86, ${alpha})`,
    customOrange: `rgba(255, 100, 0, ${alpha})`,
    customBlue: `rgba(0, 100, 255, ${alpha})`,
    customGreen: `rgba(0, 255, 100, ${alpha})`
})


class Chart extends Component {
    state = {
        chart: null
    }

    componentWillReceiveProps(nextProps) {
        console.log("receive", nextProps)
        let { chart } = this.state
        if (!chart) return

        chart.data.labels = nextProps.xAxis;
        const tuv = nextProps.yAxis.map(elem => elem.tuv)
        const ut = nextProps.yAxis.map(elem => elem.ut)
        chart.data.datasets.forEach(dataset => {
            if (dataset.label === 'tuv') dataset.data = tuv
            if (dataset.label === 'ut') dataset.data = ut
        })

        chart.update()
    }

    componentDidMount() {
        let chart = new Chartjs(this.chartRef, {
        type: "bar",
        data: {
            labels: this.props.xAxis,
            datasets: [
            {
                label: "tuv",
                type: "line",
                data: this.props.yAxis.map(elem => elem.tuv),
                borderColor: colors().blue,
                fill: false
            },
            {
                label: "ut",
                type: "line",
                data: this.props.yAxis.map(elem => elem.ut),
                borderColor: colors().red,
                fill: false
            }
            // {
            //     label: "dva",
            //     type: "bar",
            //     data: this.props.yAxis,
            //     backgroundColor: colors(0.2).customBlue,
            //     borderColor: colors().customBlue
            // },
            ]
        },
        options: {
            responsive: true,
            legend: {
            position: "top",
            labels: {
                fontColor: "white"
            }
            },
            title: {
            display: false
            },
            tooltips: {
            mode: "index",
            intersect: true,
            position: "nearest"
            },
            scales: {
            yAxes: [
                {
                ticks: {
                    fontColor: "white"
                },
                pointLabels: {
                    fontColor: "white"
                },
                gridLines: {
                    color: "rgba(255, 255, 255, 0.2)"
                }
                }
            ],
            xAxes: [
                {
                barPercentage: 0.4,
                ticks: {
                    fontColor: "white"
                },
                pointLabels: {
                    fontColor: "white"
                },
                gridLines: {
                    color: "rgba(255, 255, 255, 0.2)"
                }
                }
            ]
            }
        }
        });
        this.setState({ chart });
    }

    render() {
        return <canvas ref={e => (this.chartRef = e)} />;
    }
}

export default Chart
