import React from 'react';
import WelcomeScreen from './components/WelcomeScreen'
import CesiumGlobe from './components/CesiumGlobe'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
// import { Provider } from 'react-redux'

const theme = createMuiTheme({
    palette: {
        type: 'dark',
    },
})

const App = () => (
    <div className="App">
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <WelcomeScreen />
            <CesiumGlobe />
        </MuiThemeProvider>
    </div>
)

export default App;
