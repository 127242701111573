import React, { Component } from 'react';
import axios from 'axios'

import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Grid from "@material-ui/core/Grid";

import Chart from '../../Chart'


class BuildingSelect extends Component {
    state = {
        data: [],
        xAxis: [],
        yAxis: [],
        years: [],
        yearIndex: -1
    }

    componentDidMount() {
        axios.get('/obla2.json').then((res)=>
        {
            let upravene = JSON.parse(JSON.stringify(res.data))
                .map(elem => ({
                    date: `${elem.Y}-${elem.M}-${elem.D}`,
                    year: elem.Y,
                    dtuv: Number(elem.dtuv.toFixed(2)),
                    dut: Number(elem.dut.toFixed(2)),
                    tuv: Number(elem.tuv.toFixed(2)),
                    ut: Number(elem.ut.toFixed(2))
                }))
            this.setState({ data: upravene })

            let roky = [...new Set(upravene.map(item => item.year))].sort()
            this.setState({years: roky})
            this.selectYear(roky.length - 1)
        }).catch(err => {})
    }

    selectYear = (idx) => {
        this.setState({yearIndex: idx})

        const { years, data } = this.state
        const selectedYear = years[idx]
        let yearData = data.filter(elem => elem.year === selectedYear)
        let xAxis = yearData.map(elem => elem.date)
        let yAxis = yearData.map(elem => ({ ut: elem.ut, tuv: elem.tuv }))
        this.setState({xAxis, yAxis})
    }

    render() {
        let { selected, onClose, buildingData } = this.props
        let { years, yearIndex, xAxis, yAxis } = this.state

        let bldg = buildingData()
        let adresa = bldg ? bldg.Adresa : ''

        const selectedYear = years[yearIndex]

        let card = (
          <Dialog fullScreen open={!!selected}>
            <AppBar style={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ marginLeft: "10px", flex: 1 }}
                >
                  {adresa}
                </Typography>
              </Toolbar>
            </AppBar>
            <Card>
              <CardMedia
                image="https://ticbrno.cz/sites/default/files/plakat-predprodej/2017/liskovec.jpg"
                style={{ height: 160 }}
              />
              <CardContent>
                <Grid justify="space-between" container spacing={24}>
                    <Grid item>
                            <IconButton
                                onClick={() => this.selectYear(yearIndex - 1)}
                                disabled={yearIndex <= 0}
                                aria-label="previous year"
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">{ selectedYear }</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => this.selectYear(yearIndex + 1)}
                            disabled={yearIndex >= years.length - 1 || yearIndex < 0}
                            aria-label="next page"
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </Grid>
                </Grid>
                <Chart name={selectedYear} xAxis={xAxis} yAxis={yAxis} />
              </CardContent>
            </Card>
          </Dialog>
        );

        let smile = (
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={selected ? true : false}>
                <Card style={{ maxWidth: 450 }}>

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Bohužel pro tuto budovu nemáme data
                        </Typography>
                        <center>
                            <svg style={{fill: 'grey'}} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><circle cx="15.5" cy="9.5" r="1.5" /><circle cx="8.5" cy="9.5" r="1.5" /><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z" /></svg>
                        </center>
                    </CardContent>
                </Card>
            </Dialog>
        )

        return (
            <div>
                {selected && adresa ? card : smile}
            </div>
        );
    }
}

// let cartes = Cesium.Cartesian3(x, y, z)
// let { latitude, longitude } = elipsoid.fromCartesian(cartes)


export default BuildingSelect
